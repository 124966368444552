import styled from 'styled-components'
import { vw, mq } from '@dy/commons/styles'
import { LinkStyled } from '@dy/commons/components'
import { useTranslator } from '@/hooks'
import { useRouter } from 'next/router'

const MainWrapper = styled.main<any>`
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-column: 1 / span 6;
  grid-row: 1 / span 3;
  justify-content: flex-end;
  position: relative;
  width: 100%;
  z-index: 91;

  a, button {
    cursor: pointer;
  }

  ${mq.lessThan('nexus7')`
    margin-bottom: 87.246px;
  `}

  ${mq.lessThan('tablet')`
    margin-bottom:${vw(60, 'mobile')};
  `}

  ${mq.greaterThan('tablet')`
    flex-direction: row;
    justify-content: center;
    grid-column: 1 / span 12;
    margin-bottom: 0;
  `}

  @media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
    flex-direction: row;
    justify-content: center;
    margin-bottom: 0;

    a {
      margin: 0 10px;
    }
    
    a:first-of-type {
      margin-bottom: 0 !important;
    }

  }

  a {
    
    @media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
      height: 87px;
      width: 260px;
    
      > div:last-of-type {
        font-size: 28px;
      }
    }

    height: ${vw(87, 'mobile')};
    width: ${vw(260, 'mobile')};

    ${mq.greaterThan('nexus7')`
      height: 126.5px;
      width: 378px;
    `}
    
    ${mq.greaterThan('tablet')`
      height: ${vw(87, 'desktop')};
      width: ${vw(260, 'desktop')};
    `}

    ${mq.greaterThan('container')`
      height: 70.63px;
      width: 211.06px;
    `}
  }

  a:first-of-type {
    margin-bottom: ${vw(15, 'mobile')};
    
    ${mq.greaterThan('nexus7')`
      margin-bottom: 21.8px;
    `}
  }

  ${mq.greaterThan('tablet')`
    a:first-of-type {
      margin-bottom: unset;
      margin-right:20px;
      margin-left: auto;
    }

    a:last-of-type {
      margin-right: auto;
      margin-left:20px;
    }
  `}

  /* ${mq.greaterThan('tablet')`
    a:first-of-type {
      margin-right: 20px;
    }

    a:last-of-type {
      margin-left: 20px;
    }
  `} */
`
const link = {
  'es':{
    'bookings' : 'https://widget.thefork.com/es/e7196c9a-b780-4bc2-b5e2-257a581e9461'
  },
  'ca':{
    'bookings' : 'https://widget.thefork.com/ca/e7196c9a-b780-4bc2-b5e2-257a581e9461'
  },
  'fr':{
    'bookings' : 'https://widget.thefork.com/fr/e7196c9a-b780-4bc2-b5e2-257a581e9461'
  },
  'en':{
    'bookings' : 'https://widget.thefork.com/en/e7196c9a-b780-4bc2-b5e2-257a581e9461'
  }
}

export const Main = () => {
  const { t } = useTranslator()
  const { locale } = useRouter()
  return (
    <MainWrapper>
      <LinkStyled  href={`/pdf/iluzione_${locale}.pdf`} target='_blank' rel='noopener noreferrer' outline>{t('layout.main.menu')}</LinkStyled>
      <LinkStyled href={`${link[locale].bookings}`} target='_blank' rel='noopener noreferrer' outline>{t('layout.main.book')}</LinkStyled>
    </MainWrapper>
  )
}
